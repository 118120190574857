import { useGetPublicSoldTicketsQuery } from "../../store/api";
import { Box, Button, Dialog, Grid, LinearProgress, Paper, Typography } from "@mui/material";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { getPrimaryColorByAccountId, getThemeByAccountId, styleConstants } from "../../utils/styleConstants";
import { enqueueSnackbar } from "notistack";
import { Email, EmojiEvents, Person, Phone } from "@mui/icons-material";
import { useAppSelector } from "../../store/hooks/useAppSelector";
import { RootState } from "../../store/types/rootState";

interface SoldTicketsDashboardPublicProps {
    raffleId: string;
    limit: number;
    ticketQuantity: number | null;
    setTicketQuantity:  (ticketQuantity: number) => void;
    setSelectedTickets: Dispatch<SetStateAction<number[] | undefined>>
}
export const SoldTicketsDashboardPublic = ({ raffleId, limit, setTicketQuantity, setSelectedTickets: setSelectedTicketsHandler }: SoldTicketsDashboardPublicProps) => {
    const pageSize = limit === 10000 ? 1000 : limit;
    const [currentPage, setCurrentPage] = useState(1);
    const [openDialogWinner, setOpenDialogWinner] = useState<boolean>(false);
    const [winner, setWinner] = useState<any | undefined>({});
    const [selectedTickets, setSelectedTickets] = useState<number[]>([]);
    
    const accountId = useAppSelector(({ account }: RootState) => account.id);
    const { data: getSoldTicketsResponse } = useGetPublicSoldTicketsQuery(raffleId);

    const colorByAccountId = getPrimaryColorByAccountId(accountId) === '#FFFFFF' ? 'blue' : getPrimaryColorByAccountId(accountId);

    useEffect(() => {
        setTicketQuantity(selectedTickets.length)
        setSelectedTicketsHandler(selectedTickets)
    }, [selectedTickets])

    const saveNumber = (number: number, isInSoldTickets: boolean) => {
        if (!isInSoldTickets) {
            setSelectedTickets((prevSelected) =>
                prevSelected.includes(number)
                    ? prevSelected.filter((ticket) => ticket !== number)
                    : [...prevSelected, number]
            );
        }
    };

    const renderTicketNumber = (number: number) => {
        const isInSoldTickets = getSoldTicketsResponse && getSoldTicketsResponse.includes(number) as any;
        const isSelected = selectedTickets.includes(number);
        const color = isSelected ? "green" : isInSoldTickets ? colorByAccountId : getThemeByAccountId(accountId) === 'dark' ? "white" : "#000";
        
        return (
            <Paper 
                sx={{ 
                    m: 1, 
                    p: 1, 
                    border: `1px solid white`, 
                    width: 60, textAlign: "center", 
                    backgroundColor: isSelected ? "white" : "inherit", cursor: 'pointer' }
                } 
                onClick={() => saveNumber(number, isInSoldTickets)}
            >
                <Typography textAlign="center" variant="body1" color={color} sx={isInSoldTickets ? { cursor: 'pointer' } : {}}>
                    {number.toString().padStart(limit.toString().length - 1, '0')}
                </Typography>
            </Paper>
        );
    };

    const renderTickets = () => {
        const ticketNumbers = [];
        const startIndex = (currentPage - 1) * pageSize;
        const endIndex = Math.min(startIndex + pageSize, limit);
        
        if (getSoldTicketsResponse) {
            for (let i = startIndex; i < endIndex; i++) {
                ticketNumbers.push(
                    <Grid item key={i}>
                        {renderTicketNumber(i)}
                    </Grid>
                );
            }
        }

        return ticketNumbers;
    };

    const totalPages = Math.ceil(limit / pageSize);

    return (
        <Grid container spacing={1}>
            {renderTickets()}

            {selectedTickets.length > 0 && (
                <Box mt={2} p={2} width="100%" borderRadius={2} mx={2}>
                    <Typography variant="h6">Tus tickets:</Typography>
                    <Grid container spacing={1}>
                        {selectedTickets.map((ticket) => (
                            <Grid item key={ticket}>
                                <Paper sx={{ p: 1, width: 60, textAlign: "center" }}>
                                    <Typography fontWeight={"bold"}>{ticket.toString().padStart(limit.toString().length - 1, '0')}</Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            )}

            {limit === 10000 && (
                <Box mt={2} width="100%" display="flex" justifyContent="space-between">
                    {Array.from({ length: totalPages }, (_, i) => (
                        <Button size="small" key={i + 1} onClick={() => setCurrentPage(i + 1)} sx={{ color: currentPage === i + 1 ? "secondary" : "gray" }} variant="text">
                            {i + 1}
                        </Button>
                    ))}
                </Box>
            )}
        </Grid>
    );
};
