import React, { useState } from "react";
import { Box, Button, CircularProgress, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { useCreateVoucherImageMutation, usePaymentInitTransactionMutation } from "../../../store/api";
import { AttachFile } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/types/rootState";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { useNavigate } from "react-router-dom";
import { getPrimaryColorByAccountId, getThemeByAccountId, styleConstants } from "../../../utils/styleConstants";
interface TransferPaymentMethodProps {
  purchaserData: any;
  selectedPaymentMethod: string;
  raffleId: string;
  ticketQuantity: number;
  selectedTickets: number[] | undefined;
  totalAmount: number;
  totalUSDAmount: number;
  minPurchase: number;
  setOpen: (open: boolean) => void;
}

export const TransferPaymentMethod: React.FC<TransferPaymentMethodProps> = ({
  purchaserData,
  selectedPaymentMethod,
  raffleId,
  ticketQuantity,
  selectedTickets,
  totalAmount,
  totalUSDAmount,
  minPurchase
}: TransferPaymentMethodProps) => {

  const { enqueueSnackbar } = useSnackbar();
  const accountId = useSelector(({ account }: RootState) => account.id);
  const raffleType = useSelector(({ raffle }: RootState) => raffle.type);

  const [voucherImages, setVoucherImages] = useState<File[]>([]);
  const [operationNumber, setOperationNumber] = useState<string>("")
  
  const [initTransaction, { isLoading: isLoadingInitTransaction, data: dataInitTransaction }] = usePaymentInitTransactionMutation();
  const [createVoucher, { isLoading: isLoadingCreateVoucher }] = useCreateVoucherImageMutation();


  const handleImageChange = (files: FileList | null) => {
    if (!files) return;
    const voucher = Array.from(files).slice(0, 1);
    setVoucherImages(voucher);
  };

  const removeVoucherImage = () => {
    setVoucherImages([]);
  };


  const handleChangeOperationNumber = (event: React.ChangeEvent<HTMLInputElement>) => {
    setOperationNumber(event.target.value);
  };


  const navigate = useNavigate();

  const handleInitTransaction = async () => {
    try {

      if (voucherImages.length === 0) {
        enqueueSnackbar("Por favor adjunte el capture de la transferencia.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        return;
      }

      if (!purchaserData.name || !purchaserData.email || !purchaserData.phone) {
        enqueueSnackbar("Ingrese todos los datos de contacto.", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
        return;
      }

      if (!selectedPaymentMethod) {
        return;
      }

      const response = await initTransaction({
        raffleId,
        conf: {
          ticketQuantity,
          paymentMethodId: selectedPaymentMethod,
          purchaser: purchaserData,
          selectedTickets,
        },
      }).unwrap();

      if (
        response.status === "TRANSFER_PAYMENT_CREATED" &&
        selectedPaymentMethod === "transfer"
      ) {
        createVoucher({
          accountId: accountId,
          imageFile: voucherImages[0],
          imageName: response.purchaseOrderId,
          raffleId: raffleId,
        }).then(() => {
          enqueueSnackbar(response.description, {
            variant: "success",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
          navigate("/successPurchase")
        }).catch((error: any)  => {
          enqueueSnackbar(error, {
            variant: "error",
            anchorOrigin: {
              horizontal: "right",
              vertical: "top",
            },
          });
        });

        return;
      }

      if (response.status === "LIMIT_EXCEEDED" || response.status === "RAFFLE_NO_LONGER_AVAILABLE") {
        enqueueSnackbar(response.error, {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      } else {
        enqueueSnackbar("Error al momento de ir al pago!", {
          variant: "error",
          anchorOrigin: {
            horizontal: "right",
            vertical: "top",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar("Error al momento de ir al pago!", {
        variant: "error",
        anchorOrigin: {
          horizontal: "right",
          vertical: "top",
        },
      });
    }
  };

  interface LoadingComponentProps {
    message: string;
  }
  const LoadingComponent = ({ message }: LoadingComponentProps) => (
    <Box
      m={3}
      display="flex"
      flexDirection="column"
      alignItems="center"
      width="100%"
      justifyContent="center"
    >
      <Typography variant="h4" fontWeight={"bold"} >{message}</Typography>
      <CircularProgress
        size={40}
        variant="indeterminate"
      />
    </Box>
  );

  return (
      <Grid container>
        {(!dataInitTransaction ||
          dataInitTransaction.status === "UNAUTHORIZED_FOR_GENERATE_TICKETS") &&
          !isLoadingInitTransaction && (
            <Grid
              item
              sx={{
                width: "100%",
              }}
            >
              <Button variant="text" component="label" startIcon={<AttachFile></AttachFile>} sx={{
                border: "inherit"
              }}>
                    {raffleType === 'raffle' || raffleType === 'lottery' ? 'Subir comprobante de pago' : 'Subir screenshot de historia'}
                <input
                  type="file"
                  accept="image/*"
                  multiple
                  hidden
                  onChange={(e) => handleImageChange(e.target.files)}
                  key={`voucher-input-button`}
                />
              </Button>

              {voucherImages.map((image, index) => (
                <Box  key={`image-preview-${index}`}>
                <img src={URL.createObjectURL(image)} width={"100%"} />
                <Box display={"flex"} justifyContent={"center"}>
                  <IconButton onClick={() => removeVoucherImage()}>
                    <DeleteIcon />
                  </IconButton>
                </Box>
              </Box>
              ))}

              {/* <FormControl sx={{ width: "100%", mt: 2 }} variant="outlined">
                <InputLabel htmlFor="n-purchase-order">
                  Número de operación
                </InputLabel>
                <OutlinedInput
                  id="n-purchase-order"
                  type={"text"}
                  onChange={handleChangeOperationNumber}
                  fullWidth
                  label="Número de operación"
                />
              </FormControl> */}

              {/* operationNumber === "" || FIXME: ASKAKD */}
              <Button
                disabled={
                  voucherImages.length === 0 ||
                  !selectedPaymentMethod ||
                  isLoadingInitTransaction ||
                  totalUSDAmount === 0 || 
                  ticketQuantity === 0 ||
                  ticketQuantity < minPurchase
                }
                fullWidth
                type="submit"
                color="primary"
                sx={{
                  opacity: voucherImages.length === 0 || totalUSDAmount === 0 || ticketQuantity === 0 || ticketQuantity < minPurchase ? 0.3 : 1,
                  backgroundColor: `${getPrimaryColorByAccountId(accountId)} !important`,
                  borderColor: "#000",
                  mt: 2,
                }}
                variant="contained"
                onClick={handleInitTransaction}
              >
                {!isLoadingInitTransaction ? (
                  <>
                    {/* <Typography color={"#000"} ml={1} fontWeight={"bold"}>
                      Comprar tickets{" "}
                      {selectedPaymentMethod && `${totalAmount}$ pesos`}
                    </Typography> */}
                    <Typography color={`${getThemeByAccountId(accountId) === 'dark' ? '#000 !important' : '#FFF !important' }`} ml={1} fontWeight={"bold"}>
                      {raffleType === 'raffle' || raffleType === 'lottery' ? 'Comprar tickets' : 'Participar ahora'}
                    </Typography>
                  </>
                ) : (
                  <CircularProgress size="2rem" />
                )}
              </Button>
            </Grid>
          )}

          {dataInitTransaction && dataInitTransaction.status === 'TRANSFER_PAYMENT_CREATED' &&  !isLoadingCreateVoucher && <Box mb={20} px={10}>
              <Typography  textAlign={"center"} variant="h2">
                  Se ha creado la orden de compra, sus números llegarán a su correo una vez validemos la transferencia.
              </Typography>
              <Box display={"flex"} justifyContent={"center"}>
                <DoneAllIcon sx={{ fontSize: 60 }} color="success"></DoneAllIcon>
              </Box>
            </Box>}

        {isLoadingInitTransaction && (<LoadingComponent message="Creando orden de compra" />)}
        {isLoadingCreateVoucher && (<LoadingComponent message="Guardando comprobante, no recargue la pestaña" />)}

        
      </Grid>
  );
};

