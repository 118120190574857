import React, { useState } from "react";
import {
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { CashPaymentMethod } from "../methods/cash";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/types/rootState";
import { TransferPaymentMethod } from "../methods/transfer";
import BankAccounts from "../../raffle/bankAccounts";
import PromotionSteps from "../../raffle/promotionSteps";

interface FormPaymentProps {
  setPurchaserData: (data: any) => void;
  handlePaymentMethodSelect: (paymentMethod: string) => void;
  setOpen: (open: boolean) => void;
  availablePaymentMethods: string[];
  selectedTickets: number[] | undefined;
  purchaserData: any;
  selectedPaymentMethod: string | null;
  raffleId: string;
  ticketQuantity: number;
  totalAmount: number;
  totalAmountUSD: number;
  minPurchase: number;
}

const FormPayment: React.FC<FormPaymentProps> = ({
  purchaserData,
  setPurchaserData,
  availablePaymentMethods,
  selectedPaymentMethod,
  handlePaymentMethodSelect,
  selectedTickets,
  setOpen,
  raffleId,
  ticketQuantity,
  totalAmount,
  totalAmountUSD,
  minPurchase,
}: FormPaymentProps) => {
  const isLoggedIn = useSelector(({ authentication }: RootState) => authentication.isLoggedIn);
  const accountId = useSelector(({ account }: RootState) => account.id);
  const raffleType = useSelector(({ raffle }: RootState) => raffle.type);
  const [emailError, setEmailError] = useState<string>("");

  const validateEmail = (email: string) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return pattern.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    setPurchaserData({ ...purchaserData, email });
    if (!validateEmail(email)) {
      setEmailError("Ingrese un correo electrónico válido");
    } else {
      setEmailError("");
    }
  };


  const renderPaymentOption = () => {
    switch (selectedPaymentMethod) {
        case "cash":
          return (
            <CashPaymentMethod
              totalAmount={totalAmount}
              purchaserData={purchaserData}
              raffleId={raffleId}
              setOpen={setOpen}
              selectedPaymentMethod={selectedPaymentMethod}
              ticketQuantity={ticketQuantity}
            />
          );
        case "transfer":
            return (
              <TransferPaymentMethod
                selectedTickets={selectedTickets}
                minPurchase={minPurchase}
                totalAmount={totalAmount}
                totalUSDAmount={totalAmountUSD}
                purchaserData={purchaserData}
                raffleId={raffleId}
                setOpen={setOpen}
                selectedPaymentMethod={selectedPaymentMethod}
                ticketQuantity={ticketQuantity}
              />
            );
      default:
        return null;
    }
  };

  return (
    <form style={{
      height: '100%',
      width: '100%',
    }}>

      {raffleType === 'raffle' && <BankAccounts ticketSelectedCount={ticketQuantity}  accountId={accountId}></BankAccounts> }
      {raffleType === 'lottery' && <BankAccounts ticketSelectedCount={ticketQuantity}  accountId={accountId}></BankAccounts> }
      {raffleType === 'promo' && <PromotionSteps accountId={accountId}></PromotionSteps> }
      

      {raffleType === 'raffle' && <Typography variant="h4" textAlign={"center"} my={2} fontWeight={"bold"}>UNA VEZ REALIZADA LA TRANSFERENCIA RELLENA ESTE FORMULARIO:</Typography>}
      <TextField
        sx={{ paddingBottom: 2 }}
        id="name"
        label="Nombre"
        type="text"
        fullWidth
        variant="outlined"
        required
        value={purchaserData.name}
        inputProps={{
          maxLength: 50
        }}
        onChange={(e) =>
          setPurchaserData({ ...purchaserData, name: e.target.value })
        }
      />
      <TextField
        sx={{ paddingBottom: 2 }}
        id="email"
        label="Email"
        type="email"
        fullWidth
        variant="outlined"
        required
        error={!!emailError}
        inputProps={{
          maxLength: 50
        }}
        helperText={emailError}
        value={purchaserData.email}
        onChange={handleEmailChange}
      />
        <TextField
        sx={{ paddingBottom: 2 }}
        margin="dense"
        id="phone"
        label="Número de teléfono"
        type="text"
        fullWidth
        variant="outlined"
        required
        value={purchaserData.phone}
        inputProps={{
          maxLength: 20,
          pattern: "[0-9]*"
        }}
        onInput={(e: any) => {
          e.target.value = e.target.value.replace(/[^0-9+]/g, ''); // Filter out non-numeric characters
          setPurchaserData({ ...purchaserData, phone: e.target.value });
        }}
      />
      
      <TextField
        sx={{ paddingBottom: 2 }}
        margin="dense"
        id="phone"
        label="Número de tickets"
        disabled
        type="text"
        fullWidth
        variant="outlined"
        required
        value={ticketQuantity}
        inputProps={{
          maxLength: 12,
          pattern: "[0-9]*"
        }}
        onInput={(e: any) => {
          e.target.value = e.target.value.replace(/[^0-9+]/g, ''); // Filter out non-numeric characters
          setPurchaserData({ ...purchaserData, phone: e.target.value });
        }}
      />
      
      {isLoggedIn ? <CashPaymentMethod
              totalAmount={totalAmount}
              purchaserData={purchaserData}
              raffleId={raffleId}
              setOpen={setOpen}
              selectedPaymentMethod={"cash"}
              ticketQuantity={ticketQuantity}
            /> : <Stack direction="column">
            {renderPaymentOption()}
          </Stack>}
    </form>
  );
};

export default FormPayment;
