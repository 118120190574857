import { createTheme } from "@mui/material/styles";
import { getPrimaryColorByAccountId, getThemeByAccountId } from "./styleConstants";

export const createAppTheme = (store: any) => {

  let accountId = "";
  switch (window.location.hostname) { 
    case 'tickets.soyoscaralarcon.com':
      accountId = 'oscar-alarcon';
      break;
    case 'galenstar1.com':
      accountId = 'galenstar';
      break;
    case 'goyo2time.com':
      accountId = 'goyo2time';
      break;
    case 'agudojhonny.com':
      accountId = 'agudojhonny';
      break;
    case 'rifasport.vercel.app':
      accountId = 'rifa-sport';
      break;
    case 'lasminasdebaruta.com':
      accountId = 'minas-de-baruta';
      break;
    case 'soyoscaralarcon.com':
      accountId = 'oscar-alarcon-promo';
      break;
    case 'maikel-dominguez.com':
      accountId = 'maikel-dominguez';
      break;
    case 'localhost':
      accountId = 'galenstar';
      break;
    case 'blssd-discbar-zz.vercel.app':
      accountId = 'blessed-disc-bar-zz';
      break;
    case 'danielcontrerasrifas.com':
      accountId = 'danielcontrerasrifas';
      break;
    case 'rifas.jaimezmaikel.com':
      accountId = 'maikel-jaimez';
      break;
      case 'rifasjjmc.vercel.app':
        accountId = 'jjmc';
        break;
    case 'viprifas.com':
      accountId = 'vip-rifas';
      break;
    default:
      accountId = 'minas-de-baruta';
      break;
  }
  
          
  const primaryColor = getPrimaryColorByAccountId(accountId);
  const themeByAccountId = getThemeByAccountId(accountId);

  let bgColorDefault = "#000";
  let textColorDefault = "#FFF";

  if (themeByAccountId === 'light') {
    bgColorDefault = '#FFF'
    textColorDefault = "#000";
  }

  return createTheme({
    typography: {
      fontFamily: "Poppins",
    },
    palette: {
      mode: themeByAccountId,
      primary: {
        main: primaryColor,
      },
      background: {
        default: bgColorDefault,
        paper: bgColorDefault
      },
      secondary: {
        main: "#d82020",
      },
      success: {
        main: "#38A121",
      },
    },
    components: {
      MuiDialogContent: {
        styleOverrides: {
          root: {
            alignItems: "center",
            display: "flex",
            justifyContent: "center",
          },
        },
      },
      MuiDialogActions: {
        styleOverrides: {
          root: {
            justifyContent: "center",
            padding: "1.5rem",
            "& :not(:first-of-type)": {
              marginLeft: 24,
            },
          },
        },
      },
      MuiGrid: {
        styleOverrides: {
          root: {
            background: bgColorDefault,
          }
        }
      },
      MuiTypography: {
        styleOverrides: {
          root: {
            color: textColorDefault
          }
        }
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            '& .MuiInputBase-input': {
              color: themeByAccountId === 'dark' ? '#fff' : '#000'
            },
            '& .MuiInput-underline:before': {
              borderBottomColor: themeByAccountId === 'dark' ? '#fff' : '#000'
            },
            '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
              borderBottomColor: themeByAccountId === 'dark' ? '#fff' : '#000'
            },
            '& .MuiInput-underline:after': {
              borderBottomColor: themeByAccountId === 'dark' ? '#fff' : '#000'
            },
            '& .MuiInputBase-input::placeholder': {
              color: themeByAccountId === 'dark' ? '#fff' : '#000'
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            borderRadius: "8px",
            boxShadow: "none",
            cursor: "pointer",
          },
          contained: {
            textTransform: "initial",
          },
        },
      },
    },
  })
};

